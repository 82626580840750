$BG: #2a2a2a;
$GREEN: #78ff11;
$RED: #ff4300;
$GRAY: #9a9a9a;
$ORANGE: #fe6e03;
.dd {
  border: 1px solid red;
}

@font-face {
  font-family: DINSKI_CASUAL_CONDENSED;
  src: local("DINSKI_CASUAL_CONDENSED"), 
  url('../fonts/DINSKI_CASUAL_CONDENSED.ttf');
}
@font-face {
  font-family: FAD_SCRIPT;
  src: local("FAD_SCRIPT"), 
  url('../fonts/FAD_SCRIPT.ttf');
}
@font-face {
  font-family: HELVETICA;
  src: local("HELVETICA"), 
  url('../fonts/HELVETICA.ttf');
}
@font-face {
  font-family: LANENAR;
  src: local("LANENAR"), 
  url('../fonts/LANENAR.ttf');
}
@font-face {
  font-family: MGENPLUS;
  src: local("MGENPLUS"), 
  url('../fonts/MGENPLUS.ttf');
}
@font-face {
  font-family: NAGOYA;
  src: local("NAGOYA"), 
  url('../fonts/NAGOYA.otf');
}
@font-face {
  font-family: ROUNDED_MPLUS;
  src: local("ROUNDED_MPLUS"), 
  url('../fonts/ROUNDED_MPLUS.ttf');
}
@font-face {
  font-family: SQUIB;
  src: local("SQUIB"), 
  url('../fonts/SQUIB.ttf');
}
@font-face {
  font-family: SUNSET_CITY;
  src: local("SUNSET_CITY"), 
  url('../fonts/SUNSET_CITY.ttf');
}
html, body { height: 100%; }
body { min-width: 320px;  font-family: 'Spoqa Han Sans Neo', 'sans-serif';  overflow-y: scroll; overflow-x: hidden; background-color: $BG; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none }
p { margin: 0; }
ul { list-style: none; padding: 0; margin: 0; }

