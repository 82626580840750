@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSansNeo.css);
@import url(//spoqa.github.io/spoqa-han-sans/css/SpoqaHanSans-jp.css);
@import "init.scss";
@import "components.scss";

.page_loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0,0,0,0.3);
  top: 0;
  left: 0;
  z-index: 100000;
  display: none;
  justify-content: center;
  align-items: center;
  &.loading {
    display: flex;
  }
}

.moveable-line {
  display: none;
}
.moveable-handler {
  .moveable-line {
    display: block;
  }
}

/*
|--------------------------------------------------------------------------
| syns style
|--------------------------------------------------------------------------
| 에디터 스타일
*/

.next_btn {
  width: 100%;
  display: block;
  background: $RED;
  border: 1px solid $RED;
  color: white;
  font-size: 20px;
  font-weight: bold;
  padding: 18px 0 18px 0;
  align-self: flex-end;
  &:hover {
    background: darken( $RED, 5% );
    border-color: darken( $RED, 5% );
  }
  &:active {
    background: darken( $RED,10% );
    border-color: darken( $RED,10% );
  }
}
.dyns_content {
  flex: 1;
  margin: 10px 0 0;
  display: flex;
  justify-content: center;
  &.mobile_content {
    min-height: 250px;
  }
  &.ruler {
    .h, .w {
      display: block !important;
    }
  }
  &.group_content {
    .item_wrap {
      pointer-events: none;
    }
  }
  .item_wrap {
    position: absolute;
    z-index: 10000;
    top: 30%;
    &.temp {
      top: -10000000px;
    }
    &.neon {
      &.c-fd8e01 .item {
        text-shadow:
        0 0 7px #fd8e01,
        0 0 10px #fd8e01,
        0 0 21px #fd8e01,
        0 0 42px #fd8e01,
        0 0 82px #fd8e01,
        0 0 92px #fd8e01,
        0 0 102px #fd8e01,
        0 0 151px #fd8e01;
        @keyframes flicker1 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#fd8e01);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker1 2s infinite alternate;   
        }
      }
      &.c-ffd203 .item {
        @include neon(#ffd203);
        @keyframes flicker2 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#ffd203);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker2 2s infinite alternate;   
        }
      }
      &.c-fec657 .item {
        @include neon(#fec657);
        @keyframes flicker3 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#fec657);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker3 2s infinite alternate;   
        }
      }
      &.c-00bcec .item {
        @include neon(#00bcec);
        @keyframes flicker4 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#00bcec);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker4 2s infinite alternate;   
        }
      }
      &.c-0000fe .item {
        @include neon(#0000fe);
        @keyframes flicker5 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#0000fe);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker5 2s infinite alternate;   
        }
      }
      &.c-ff0e1c .item {
        @include neon(#ff0e1c);
        @keyframes flicker6 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#ff0e1c);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker6 2s infinite alternate;   
        }
      }
      &.c-fe00dc .item {
        @include neon(#fe00dc);
        @keyframes flicker7 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#fe00dc);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker7 2s infinite alternate;   
        }
      }
      &.c-ffffff .item {
        @include neon(#ffffff);
        @keyframes flicker8 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#ffffff);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker8 2s infinite alternate;   
        }
      }
      &.c-c401ab .item {
        @include neon(#c401ab);
        @keyframes flicker9 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#c401ab);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker9 2s infinite alternate;   
        }
      }
      &.c-00e847 .item {
        @include neon(#00e847);
        @keyframes flicker10 {
          0%, 18%, 22%, 25%, 53%, 57%, 100% {
            @include neon(#00e847);
          }
          20%, 24%, 55% { text-shadow: none; }    
        }
        &.fliker {
          animation: flicker10 2s infinite alternate;   
        }
      }
    }
    
    .h, .w {
      position: absolute;
      border: 1px solid white;
      margin: auto;
      display: none;
      span {
        color: white;
        font-size: 12px;
        position: absolute;
        margin: auto;

      }
    }
    .h {
      width: 10px;
      height: 100%;
      top: 0;
      bottom: 0;
      border-right: none;
      left: -25px;
      span {
        height: 20px;
        top: 0;
        bottom: 0;
        right: 20px;
      }
    }
    .w {
      width: 100%;
      height: 10px;
      border-top: none;
      bottom: -20px;
      left: 0;
      right: 0;
      span {
        width: 100%;
        text-align: center;
        bottom: -25px;
      }
    }
    .item {
      display: inline-block;
      position: relative;
      textarea {
        width: 100%;
        height: 100%;
        border: transparent;
        background: transparent;
        font-size: 40px;
        line-height: 1;
        overflow: hidden;
        resize: none;
        outline: none;
        position: absolute;
        left: 0;
        top: 0;
        padding: 6px;
      }
      .guide {
        width: 100%;
        height: 100%;
        position: absolute;
        border: 1px solid $GREEN;
        background: transparent;
        pointer-events: none;
        display: none;
      }

      .sync {
        min-width: 100px;
        font-size: 40px;
        line-height: 1;
        visibility: visible;
        padding: 6px;
        border: transparent;
        pointer-events: none;
        color: transparent;
      }
      .svg {
        font-size: 80px;
      }
    }
  }
}
.dyns_background {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
  &.light {
    .bg > div {
      opacity: 1;
    }
  }
  .bg {
    width: 100%;
    height: 100vh;
    > div {
      height: 100vh;
      background-position: center top;
      opacity: 0.2;
    }
  }
}

.syns_layout {
  min-width: 1200px;
  display: flex;
  min-height: 100vh;
  &.result_layout {
    min-width: 300px;
  }
  > div {
    display: flex;
    flex-direction: column;
  }
  .left {
    flex: 1;
    background: darken( #2a2a2a, 2% );
    position: relative;
    
  }
  .right {
    width: 450px;
    color: white;
    background: #2f2f2f;
    &.result {
      width: 100%;
      max-width: 450px;
      margin: 0 auto;
    }
  }
  .dyns_status {
    height: 50px;
    margin-top: 30px;
    text-align: center;
    position: relative;
    label {
      margin: 0 5px;
    }
    .price {
      color: white;
      font-size: 25px;
      font-weight: bold;
      position: absolute;
      top: 0;
      right: 0;
      text-align: right;
    }
    .size {
      font-size: 12px;
    }
    .reset {
      width: 32px;
      height: 32px;
      border: none;
      padding: 0;
      background: transparent;
      color: white;
      margin-left: 4px;
      i {
        font-size: 32px;
      }
    }
  }
  
  
  .tab_box {
    padding: 20px;
    &.disabled {
      .tabs {
        background-color: #373737;
        border-color: #464646;
        li {
          cursor: default;
          color: #9a9a9a !important;
          &:last-child {
            border-left: 1px solid #464646;
          }
        }
      }
    }
    .tabs {
      display: flex;
      border: 2px solid $GREEN;
      border-radius: 10px;
      background: #1d1d1d;
      padding: 12px 0;
      li {
        flex: 1;
        text-align: center;
        padding: 6px 0 0;
        font-size: 20px;
        font-weight: bold;
        color: $GRAY;
        cursor: pointer;
        transition: color 0.3s;
        &:last-child {
          border-left: 1px solid #3d3d3d;
        }
        &:hover {
          color: darken( $GRAY, 10% );
        }
        &.active {
          color: $ORANGE;
        }
        i {
          font-size: 40px;
        }
        p {
          margin-top: -5px;
        }
      }
    }
  }

  .dyns_options {
    flex: 1;
    padding: 20px;
    
    .colors {
      .el-radio {
        margin-bottom: 10px;
      }
    }
    .add {
      color: $GREEN;
      font-size: 12px;
      text-align: right;
      display: block;
    }
    .adapter {
      margin-top: 10px;
      .btn-radio {
        width: 60px;
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  .background_box {
    .label {
      font-size: 16px;
      font-weight: bold;
      padding-left: 20px;
    }
    .thumbs {
      margin-top: 20px;
      margin-bottom: 20px;
      .slick-center .thumb img {
        border: 1px solid $GREEN;
      }
      .thumb {
        padding: 0 5px;
        img {
          width: 100%;
          border-radius: 10px;
        }
      }
    }
  }
  .svg_box {
    flex: 1;
    .scroll {
      height: 80vh;
      overflow-y: auto;
      padding: 10px 20px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
    ul {
      margin-left: -10px;
      &:after {
        content: '';
        display: table;
        clear: both;
      }
      li {
        width: calc(100%/3);
        float: left;
        padding-left: 10px; 
        margin-bottom: 10px;
        div {
          background: #2a2a2a;
          border-radius: 6px;
          border: 1px solid #464646;
          cursor: pointer;
          text-align: center;
          padding: 20px 0;
          &:hover {
            border-color: $GREEN;
            i {
              color: white;
            }
          }
        }
        i {
          font-size: 80px;
          color: #9a9a9a;
        }
      }
    }
  }
  .result_box {
    flex: 1;
    .thumb {
      border-bottom: 1px solid #3e3e3e;
      padding: 30px 0;
      margin-bottom: 30px;
      .thumb_wrap {
        max-width: 266px;
        margin: 0 auto;
        .img_box {
          width: 100%;
          padding-bottom: 98%;
          border: 1px solid #9a9a9a;
          background-position: center  !important;
          background-size: contain !important;
          background-repeat: no-repeat;
        }
        button {
          margin-top: 20px;
        }
      }
    }
    .back_btn {
      width: 100%;
      height: 40px;
      display: block;
      color: white;
      background: transparent;
      border: 2px solid #fe6e03;
      border-radius: 20px;
      font-size: 16px;
      font-weight: bold;
      align-self: flex-end;
      margin-top: 30px;
      i {
        font-size: 30px;
        vertical-align: middle;
        margin-right: 10px;
      }
    }
  }
  .customer {
    padding: 40px 20px 0;
    margin-bottom: 20px;
    border-top: 1px solid #3e3e3e;
    h1 {
      font-size: 22px;
      margin-bottom: 30px;
      color: $GREEN;
      text-align: center;
      font-weight: bold;
    }
    input {
      background: transparent;
      border: 1px solid #464646;
      outline: none;
      color: #9a9a9a;
      font-size: 18px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  .agree_box {
    padding: 0 20px 0;
    margin-bottom: 30px;
    .agree {
      height: 90px;
      border: 1px solid #464646;
      margin-bottom: 10px;
      white-space: pre-wrap;
      overflow-y: scroll;
      font-size: 14px;
      font-family: 'Spoqa Han Sans Neo', 'sans-serif';
      color: #646f84;
      padding: 0 10px;
      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: #888;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}


.layer_pop {
  width: 100%;
  height: 430px;
  background: #2a2a2a;
  position: fixed;
  bottom: 0;
  transform: translateY( 430px );
  transition: transform 0.3s;
  &.open_back_pop,
  &.open_color_pop {
    transform: translateY( 0 );
  }
  button {
    color: white;
    background: transparent;
    border: transparent;
    position: absolute;
    top: 20px;
    right: 20px;
    i {
      font-size: 50px;
    }
  }
  h2 {
    color: $GREEN;
    font-size: 35px;
    text-align: center;
    margin-top: 50px;
    font-weight: bold;
  }
  .color_guide {
    margin: 0 auto 20px;
    li {
      display: inline-block;
      padding: 20px;
      text-align: center;
      h3 {
        color: white;
        font-size: 16px;
        margin-top: 15px;
      }
    }
  }
  .backing_type {
    width: 1120px;
    margin: 0 auto 20px;
    display: table;
    li {
      width: 25%;
      display: table-cell;
      padding: 20px;
      text-align: center;
      img {
        width: 100%;
      }
      h3 {
        color: $GREEN;
        font-size: 16px;
        font-weight: bold;
        margin-top: 15px;
      }
      h4 {
        font-size: 14px;
        color: white;
        margin-top: 15px;
      }
    }
  }
  p {
    color: white;
    text-align: center;
    font-size: 16px;
  }
}

 
.moveable-area {
  position: relative;
}
.group_ruler {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  .h, .w {
    position: absolute;
    border: 1px solid white;
    margin: auto;
    span {
      color: white;
      font-size: 12px;
      position: absolute;
      margin: auto;
    }
  }
  .h {
    width: 10px;
    height: 100%;
    top: 0;
    bottom: 0;
    border-right: none;
    left: -25px;
    span {
      height: 20px;
      top: 0;
      bottom: 0;
      right: 20px;
    }
  }
  .w {
    width: 100%;
    height: 10px;
    border-top: none;
    bottom: -20px;
    left: 0;
    right: 0;
    span {
      width: 100%;
      text-align: center;
      bottom: -25px;
    }
  }
}

 




/*
|--------------------------------------------------------------------------
| mobile style
|--------------------------------------------------------------------------
| 에디터 스타일
*/
.mobile_layout {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  .next_btn {
    padding: 10px 0 10px 0;
    position: relative;
  }
  .dyns_status {
    padding: 15px 0 0 10px;
    height: 50px;
    position: relative;
    label {
      margin: 0 5px;
    }
    .reset {
      width: 32px;
      height: 32px;
      border: none;
      padding: 0;
      background: transparent;
      color: white;
      margin-left: 4px;
      i {
        font-size: 32px;
      }
    }
  }
  .option_box {
    width: 100%;
    align-self: flex-end;
    background: #2b2b25;
    padding: 20px 20px 10px;
    color: white;
    position: relative;
    .mobile_tab_box {
      position: absolute;
      top: -60px;
      left: 20px;
      &.disabled {
        button {
          background-color: #373737;
          border-color: #464646;
          color: #9a9a9a !important;
        }
      }
      li {
        display: inline-block;
        margin-right: 10px;
        &.active {
          button {
            color: $ORANGE;
          }
        }
      }
      button {
        width: 50px;
        height: 50px;
        border: 2px solid $GREEN;
        border-radius: 25px;
        background: #1d1d1d;
        color: #9a9a9a;
        font-weight: bold;
        text-align: center;
        font-size: 14px;
        padding: 0;
        padding-top: 2px;
      }
    }
    .scroll_tab {
      overflow-x: scroll;
      padding-bottom: 15px;
      ul {
        width: 750px;
        display: flex;
        justify-content: space-between;
        button {
          border: none;
          background: transparent;
          color: #9a9a9a;
          font-weight: bold; 
          display: block;
          white-space: nowrap;
          &.active {
            color: white;
          }
          &[disabled] {
            color: #3d3d3d;
            text-decoration: line-through;
          }
        }
      }
    }
    .options_tab {
      height: 100px;
      overflow-x: scroll;
      overflow-y: none;
      ul {
        display: none;
        &.show {
          display: flex;
        }
      }
      .background_group {
        display: none;
        padding-top: 10px;
        &.show {
          display: block;
        }
      }
    }
    .size_group,
    .backing_group,
    .font_group {
      width: calc( 160px * 5);
      flex-wrap: wrap;
      padding-top: 5px;
      li {
        width: 150px;
        margin-right: 10px;
        &.active button {
          border-color: $GREEN;
        }
        button {
          width: 150px;
          display: block;
          background: #2a2a2a;
          color: #9a9a9a;
          font-weight: bold; 
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          border: 1px solid #464646;
          padding: 5px 15px;
          margin-bottom: 10px;
          border-radius: 6px;
        }
      }
    }
    .backing_color_group,
    .color_group {
      li {
        padding-top: 10px;
        margin-right: 20px;
      }
    }
    .size_group {
      width: calc( 160px * 4);
    }
    .backing_color_group {
      width: calc( 160px * 2);
    }
    .backing_group {
      width: calc( 160px * 4);
    }
    .background_group {
      .thumbs {
        .slick-center .thumb img {
          border: 1px solid $GREEN;
        }
        .thumb {
          padding: 0 5px;
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
  .svg_scroll_tab {
    overflow-x: scroll;
    padding-bottom: 15px;
    ul {
      width: calc( 130px * 168);
      display: flex;
      justify-content: space-between;
      div {
        width: 120px;
        background: #2a2a2a;
        border-radius: 6px;
        border: 1px solid #464646;
        cursor: pointer;
        text-align: center;
        padding: 20px 0;
        &:hover {
          border-color: $GREEN;
          i {
            color: white;
          }
        }
      }
      i {
        font-size: 80px;
        color: #9a9a9a;
      }
    }
  }
}

