 .ut-cursor-pointer {
  cursor: pointer;
 }
 
@mixin neon($color: #fd8e01) {
  text-shadow:
  0 0 7px $color,
  0 0 10px $color,
  0 0 21px $color,
  0 0 42px $color,
  0 0 82px $color,
  0 0 92px $color,
  0 0 102px $color,
  0 0 151px $color;
}
 
.moveable-rotation-control,
.moveable-direction,
.moveable-line {
  background: $GREEN !important;
  border-color: lighten( $GREEN, 30% ) !important;
}
 
.moveable-group {
  .moveable-line {
    background: none !important;
  }
}
.remove-item {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    color: $GREEN;
    font-size: 28px;
    background: $BG;
    border: transparent;
    padding: 0;
    position: relative;
    line-height: 1;
    i {
      top: -4px;
      left: -4px;
      position: absolute;
      z-index: 1;
    }
}
.label-group {
  display: flex;
  margin-bottom: 20px;
  &.label-group-column {
    flex-direction: column;
    > label {
      width: 100%;
    }
  }
  > label {
    width: 150px;
    font-size: 16px;
    font-weight: bold;
  }
  > div {
    flex: 1;
    min-width: 0;
  }
}

.btn {
  font-weight: bold;
}
.btn-block {
  width: 100%;
  display: block;
}
.btn-outline-primary {
	color: $GREEN;
	border-color: $GREEN;
  border-width: 2px;
	transition: background 0.2s;
  box-shadow: none !important;
	&:hover {
		color: black;
		background: $GREEN;
    border-color: $GREEN;
  }
	&:focus,
	&.focus {
		color: black;
		background-color: darken( $GREEN, 10% );
		border-color: darken( $GREEN, 10% );
	}
	&.disabled,
	&:disabled {
		background-color: #373737;
		border-color: #464646;
		color: #9a9a9a;
	}
	&:not(:disabled):not(.disabled):active,
	&:not(:disabled):not(.disabled).active {
		background-color: darken( $GREEN, 15% );
		border-color: darken( $GREEN, 15% );
	}
}

.cp-switch {
	cursor: pointer;
  vertical-align: top;
	.cp-switch-group {
    position: relative;
    .cp-label-left {
      position: absolute;
      top: 5px;
      left: 10px;
      font-size: 14px;
      color: $GREEN;
      font-weight: bold;
      opacity: 0;
      -webkit-transition: opacity 0.7s color .3s ease-in-out;
      transition: opacity 0.7s color, .3s ease-in-out;
    }
    .cp-label-right {
      position: absolute;
      top: 5px;
      right: 10px;
      font-size: 14px;
      color: $GREEN;
      font-weight: bold;
      opacity: 1;
      -webkit-transition: opacity 0.7s, color .3s ease-in-out;
      transition: opacity 0.7s, color, .3s ease-in-out;
    }
		.cp-switch-style {
			width: 76px;
      height: 30px;
      background: transparent;
      border-radius: 15px;
      display: inline-block;
      position: relative;
      -webkit-transition: all .3s ease-in-out;
      transition: all .3s ease-in-out;
      border: 2px solid $GREEN;
			&:before{
				width: 21px;
        height: 21px;
        display: block;
        position: absolute;
        content: '';
        background-color: $GREEN;
        -webkit-border-radius: 50%;
        border-radius: 50%;
        left: 4.5%;
        top: 2px;
        -webkit-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
        box-shadow: 0 1px 2px 0 rgba(0,0,0,0.16);
        z-index: 1;
			}
		}
    input[type="checkbox"] {
      display: none;
      &[disabled] {
        ~ .cp-switch-style {
          cursor: not-allowed;
          background-color: #464646;
          border-color: #9a9a9a;
          &:before {
            background-color: #9a9a9a;
          }
        }
        ~ .cp-label-left {
          color: #9a9a9a;
          cursor: not-allowed;
        }
        ~ .cp-label-right {
          color: #9a9a9a;
          cursor: not-allowed;
        }
      }
      &:checked {
        ~ .cp-switch-style {
          &:before {
            left: 66%;
          }
        }
        ~ .cp-label-left {
          opacity: 1;
        }
        ~ .cp-label-right {
          opacity: 0;
        }
        &[disabled] {
          ~ .cp-switch-style:before {
            background-color: red !important;
          }
        }
      }
    }
	}
}

.cp-cwitch {
  height: 30px;
  vertical-align: top;
  cursor: pointer;
  .cp-cwitch-style {
    width: 30px;
    height: 30px;
    background: transparent;
    border-radius: 15px;
    display: inline-block;
    text-align: center;
    position: relative;
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border: 2px solid $GREEN;
    padding-top: 3px;
    i {
      color: $GREEN;
      font-size: 18px;
      font-weight: bold;
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked {
      ~ .cp-cwitch-style {
        background: $GREEN;
        i {
          color: #2a2a2a;
        }
      }
    }
    &[disabled] {
      ~ .cp-cwitch-style {
        cursor: not-allowed;
        background-color: #464646;
        border-color: #9a9a9a;
        i {
          color: #9a9a9a;
        }
      }
    }
  }
}


$EL_CHK: black;
$EL_CHK_DD: #464646;
$EL_BG: tranparent;
$EL_BD: $GREEN;
$EL_BD_HV: #444444;
$EL_BG_CHK: $GREEN;
$EL_BD_CHK: $GREEN;
$EL_BD_CHK_HV: #444444;
$EL_BG_DD: #373737;
$EL_BD_DD: #373737;
$EL_BD_DD_HV: #D3DCE6;
$EL_BG_CHK_DD: #373737;
$EL_BD_CHK_DD: #373737;
.el-radio,
.el-checkbox {
	position:relative;
	display: block;
	cursor: pointer;
	margin-bottom: 0;
	span:not(.el-checkbox-style) {
		font-weight: normal;
		vertical-align: middle;
	}
  &.color-fd8e01 .el-radio-style:before {
    background: #fd8e01 !important;
    border-color: #fd8e01 !important;
  }
  &.color-ffd203 .el-radio-style:before {
    background: #ffd203 !important;
    border-color: #ffd203 !important;
  }
  &.color-fec657 .el-radio-style:before {
    background: #fec657 !important;
    border-color: #fec657 !important;
  }
  &.color-00bcec .el-radio-style:before {
    background: #00bcec !important;
    border-color: #00bcec !important;
  }
  &.color-0000fe .el-radio-style:before {
    background: #0000fe !important;
    border-color: #0000fe !important;
  }
  &.color-ff0e1c .el-radio-style:before {
    background: #ff0e1c !important;
    border-color: #ff0e1c !important;
  }
  &.color-fe00dc .el-radio-style:before {
    background: #fe00dc !important;
    border-color: #fe00dc !important;
  }
  &.color-ffffff .el-radio-style:before {
    background: #ffffff !important;
    border-color: #ffffff !important;
  }
  &.color-c401ab .el-radio-style:before {
    background: #c401ab !important;
    border-color: #c401ab !important;
  }
  &.color-00e847 .el-radio-style:before {
    background: #00e847 !important;
    border-color: #00e847 !important;
  }
  &.color-222222 .el-radio-style:before {
    background: #222222 !important;
    border-color: #222222 !important;
  }
  &.color-868686 .el-radio-style:before {
    background: #868686 !important;
    border-color: #868686 !important;
  }
  &.color-fefe00 .el-radio-style:before {
    background: #fefe00 !important;
    border-color: #fefe00 !important;
  }
  &.color-000000 .el-radio-style:before {
    background: #000000 !important;
    border-color: #000000 !important;
  }
	&.el-inline{
		display: inline-block;
		margin-right: 10px;
	}
	&.el-single{
		display: inline-block;
		margin-right: 0px;
	}
	&.el-control{
		display: inline-block;
		margin-right: 10px;
		margin-top: 6px;
	}
  &.el-lg {
    & > input[type="radio"] ~ .el-radio-style:before,
    & > input[type="checkbox"] ~ .el-checkbox-style:before {
      width: 2.5em;
      height: 2.5em;
    }
    & > input[type="radio"] ~ .el-radio-style:after,
    & > input[type="checkbox"] ~ .el-checkbox-style:after {
      width: 1em;
      height: 1em;
      top: 0.7em;
				left: 0.7em;
    }
  }
	& > input[type="radio"],
	& > input[type="checkbox"] {
		display: none;
	}
	& > input[type="radio"][disabled],
	& > input[type="checkbox"][disabled] {
		cursor: not-allowed;
	}
	& > input[type="radio"] ~ .el-radio-style,
	& > input[type="checkbox"] ~ .el-checkbox-style {
		position: relative;
		display: inline-block;
		width: 1.5em;
		height: 1.5em;
		vertical-align: middle;
		cursor: pointer;
		font-size: 14px;
	}
	& > input[type="radio"] ~ .el-radio-style:hover:before,
	& > input[type="checkbox"] ~ .el-checkbox-style:hover:before {
		border-color: $EL_BD_HV;
	}
	& > input[type="radio"]:checked ~ .el-radio-style:hover:before,
	& > input[type="checkbox"]:checked ~ .el-checkbox-style:hover:before {
		border-color: $EL_BD_CHK_HV;
	}
	& > input[type="radio"] ~ .el-radio-style:before,
	& > input[type="checkbox"] ~ .el-checkbox-style:before {
		position: absolute;
		top: 0;
		left: 0;
		display: inline-block;
		width: 1.4em;
		height: 1.4em;
		content: '';
		background: $EL_BG;
		border: 1px solid $EL_BD;
	}
	& > input[type="radio"] ~ .el-radio-style:after,
	& > input[type="checkbox"] ~ .el-checkbox-style:after {
		position: absolute;
		display: none;
		content: '';
	}
	& > input[type="radio"][disabled] ~ .el-radio-style,
	& > input[type="checkbox"][disabled] ~ .el-checkbox-style {
		cursor: not-allowed;
	}
	& > input[type="radio"][disabled] ~ .el-radio-style:hover,
	& > input[type="radio"][disabled] ~ .el-radio-style:before,
	& > input[type="radio"][disabled] ~ .el-radio-style:after,
	& > input[type="checkbox"][disabled] ~ .el-checkbox-style:hover,
	& > input[type="checkbox"][disabled] ~ .el-checkbox-style:before,
	& > input[type="checkbox"][disabled] ~ .el-checkbox-style:after {
		cursor: not-allowed;
	}

	& > input[type="radio"][disabled] ~ .el-radio-style:hover:before,
	& > input[type="checkbox"][disabled] ~ .el-checkbox-style:hover:before {
		border: 1px solid $EL_BD_DD_HV;
		animation-name: none;
	}
	& > input[type="radio"]:checked ~ .el-radio-style:before,
	& > input[type="checkbox"]:checked ~ .el-checkbox-style:before {
		animation-name: none;
	}
	& > input[type="radio"]:checked ~ .el-radio-style:after,
	& > input[type="checkbox"]:checked ~ .el-checkbox-style:after {
		display: block;
	}
	& > input[type="radio"] {
		& ~ .el-radio-style {
			&:before { // 컨테이너 스타일
				border-radius: 50%;
			}
			&:after { // 요소 스타일
				top: 0.4em;
				left: 0.4em;
				width: 0.6em;
				height: 0.6em;
				border-radius: 50%;
				background: $EL_CHK;
			}
		}
		&:checked {
			& ~ .el-radio-style:before {
				background: $EL_BG_CHK;
				border: 1px solid $EL_BD_CHK;
			}
			& ~ span {
				color: $GREEN;
			}
			&[disabled] ~ .el-radio-style {
				&:before {
					background: $EL_BG_CHK_DD !important;
					border: 1px solid $EL_BD_CHK_DD !important;
				}
				&:after {
					background: $EL_CHK_DD;
				}
			}
		}
		&[disabled] ~ .el-radio-style:before {
			background: $EL_BG_DD !important;
			border-color: $EL_BD_DD !important;
		}
	}

	& > input[type="checkbox"] {
		& ~ .el-checkbox-style {
			&:before { // 컨테이너 스타일
				border-radius: 3px;
			}
			&:after { // 요소 스타일
				top: 0.15em;
				left: 0.5em;
				box-sizing: border-box;
				width: 0.4em;
				height: 0.85em;
				transform: rotate(45deg);
				border-right: 1px solid $EL_CHK;
				border-bottom: 1px solid $EL_CHK;
			}
		}
		&:checked {
			& ~ .el-checkbox-style:before {
				background: $EL_BG_CHK;
				border: 1px solid $EL_BD_CHK;
			}
			& ~ span {
 				color: $GREEN;
			}
			&[disabled] ~ .el-checkbox-style {
				&:before {
					background: $EL_BG_CHK_DD;
					border: 1px solid $EL_BD_CHK_DD;
				}
				&:after {
					border-color: $EL_CHK_DD;
				}
			}
		}
		&[disabled] ~ .el-checkbox-style:before {
			background: $EL_BG_DD;
			border-color: $EL_BD_DD;
		}
	}
	.el-label {
		font-weight: 300;
	}
}

.el-dropdown {
  button {
    width: 100%;
    display: block;
    background: $BG;
    border: 2px solid #464646;
    color: $GRAY;
    font-weight: bold;
    transition: background 0.2s;
    box-shadow: none !important;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
    overflow: hidden; 
    white-space: nowrap; 
    text-overflow: ellipsis;  
    &:after {
      position: absolute;
      top: 17px;
      right: 10px;
    }
    &:focus,
    &.focus,
    &:hover {
      color: $GRAY;
      background: darken( $BG, 5% );
      border-color: #464646;
    }
    &.disabled,
    &:disabled {
      background: lighten( $BG, 5% );
      border: 2px solid #464646;
      color: $GRAY;
    }
    &:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background: darken( $BG, 20% );
      border-color: #464646;
    }
  }
  .dropdown-menu {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
    background: $BG;
    border: 2px solid #464646;
    &::-webkit-scrollbar-track{
      background-color: transparent;
    }
    &::-webkit-scrollbar{
        width: 4px;
        background-color: transparent;
    }
    &::-webkit-scrollbar-thumb{
        background-color: $GREEN;
    }
    a {
      overflow: hidden; 
      white-space: nowrap; 
      text-overflow: ellipsis;  
      color: #c0c9d5;
      border: 2px solid transparent;
      &.active,
      &:focus,
      &:hover {
        background: transparent;
        border: 2px solid $GREEN;
        outline: none;
      }
      &:focus {
        color: $GREEN;
      }
    }
  }
}

label.btn-radio {
	margin: 0;
  display: block;
	& > input[type="radio"],
	& > input[type="checkbox"] {
		display: none;
	}
	& > input[type="radio"][disabled],
	& > input[type="checkbox"][disabled] {
		cursor: not-allowed;
	}
	& > input[type="radio"]:not(:disabled):checked ~ .btn-style,
	& > input[type="checkbox"]:not(:disabled):checked ~ .btn-style {
		background: transparent;
    border: 2px solid $GREEN;
    outline: none;
    color: $GREEN;
	}
	.btn-style {
		height: 40px;
		color: #9a9a9a;
		background: $BG;
    display: block;
    border: 2px solid #464646;
		cursor: pointer;
		font-size: 16px;
		padding-left: 5px;
		padding-right: 5px;
    padding-top: 7px;
    overflow : hidden; 
    white-space: nowrap;
    transition: background 0.2s;
    &:hover {
			color: $GRAY;
      background: darken( $BG, 5% );
      border-color: #464646;
		}
		&.disabled {
      background-color: #373737;
      border-color: #464646;
      color: #9a9a9a;
		}
		&:not(:disabled):not(.disabled):active,
    &:not(:disabled):not(.disabled).active {
      background: darken( $BG, 20% );
      border-color: #464646;
    }
 
	}
}

.grid-row {
  margin-left: -10px;
  &:after {
    content: '';
    display: table;
    clear: both;
  }
  .grid-col {
    width: calc( 100% / 2 );
    float: left;
    padding-left: 10px; 
    margin-bottom: 10px;
  }
}